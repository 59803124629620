<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" width="1000px" persistent>
        <v-card>
          <v-card-title class="primary py-3">
            <span class="headline white--text"> Importar Artículos y Servicios</span>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-form ref="formArchive" v-model="validArchive" lazy-validation>
              <v-row>
                <v-col cols="10">
                  <v-file-input
                    dense
                    v-model="archive"
                    show-size
                    label="Archivo"
                    clearable
                    id="files"
                    ref="files"
                    :rules="rulesArchive"
                    prepend-icon="mdi-file"
                  ></v-file-input>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        class="white--text mr-1"
                        fab
                        small
                        v-on="on"
                        @click="handleFilesUpload(archive)"
                      >
                        <v-icon dark>mdi-cloud-upload</v-icon>
                      </v-btn>
                    </template>
                    <span>CARGAR ARCHIVO</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" class="white--text mx-1" fab small @click="exportarModelo()" v-on="on">
                        <v-icon dark>mdi-file-table</v-icon>
                      </v-btn>
                    </template>
                    <span>DESCARGAR FORMATO</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-data-table
                    dense
                    :headers="headerDetalle"
                    :items="listado"
                    class="border"
                    multi-sort
                    :mobile-breakpoint="0"
                    :items-per-page="-1"
                    no-results-text="No se encontraron datos"
                    no-data-text="No se encontraron datos"
                    :search="search"
                    item-class="class"
                    :fixed-header="true"
                    :height="height"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()"> Cancelar </v-btn>
            <v-btn color="primary" text @click="guardar()"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="showLoading" class="progress-background">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx/xlsx.mjs';
export default {
  name: 'Importar',
  props: {
    dialog: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    height: 130,
    search: '',
    archive: null,
    validArchive: true,
    rulesArchive: [
      (value) => !!value || 'Campo requerido.',
      (value) => !value || value.size < 4000000 || 'El archivo debe de tener menos de 4MB!',
      (value) => {
        if (value === null || value === undefined) return true;
        const res = value.name.slice(value.name.lastIndexOf('.') + 1, value.name.length).toLowerCase();
        if (res == 'csv' || res == 'xlsx' || res == 'xls') return true;
        return "Formatos aceptados son '.csv', '.xlsx' , '.xls'";
      }
    ],
    headerDetalle: [
      { text: 'N°', value: 'numero', width: '60', class: 'primary' },
      { text: 'CUENTA MAYOR', value: 'idcuentamayor', width: '160', class: 'primary' },
      { text: 'GRUPO', value: 'grupo', width: '160', class: 'primary' },
      { text: 'CUENTA CONTABLE', value: 'idcuentacontable', width: '190', class: 'primary' },
      { text: 'SERVICIO', value: 'idservicio', width: '160', class: 'primary' },
      { text: 'DESCRIPCIÓN', value: 'descripcion', width: '200', class: 'primary' },
      { text: 'PRECIO', value: 'precio', width: '200', class: 'primary' },
      { text: 'VER OPEX CAMPO', value: 'veropexcampo', width: '200', class: 'primary' }
    ],
    listado: [],
    showLoading: false
  }),
  methods: {
    close() {
      this.listado = [];
      this.archivo = null;
      this.validArchive = true;
      this.height = 130;
      this.$emit('close');
    },
    async handleFilesUpload(archivo) {
      if (this.$refs.formArchive.validate()) {
        this.listado = [];
        var f = archivo;
        var reader = new FileReader(archivo);
        reader.onload = (e) => {
          var data = e.target.result;
          data = new Uint8Array(data);

          var workbook = XLSX.read(data, {
            type: 'array'
          });

          /* DO SOMETHING WITH workbook HERE */
          var first_sheet_name = workbook.SheetNames[0];
          /* Get worksheet */
          var worksheet = workbook.Sheets[first_sheet_name];
          //It will prints with header and contents ex) Name, Home...
          var json = XLSX.utils.sheet_to_json(worksheet, {
            header: 1
          });
          json.shift();
          var detallesAux = [];
          json.forEach((element) => {
            if (element[0] != null)
              detallesAux.push({
                numero: detallesAux.length + 1,
                idcuentamayor: element[0] ? element[0] : '',
                grupo: element[1] ? element[1] : '',
                idcuentacontable: element[2] ? element[2] : '',
                idservicio: element[3] ? element[3] : '',
                descripcion: element[4] ? element[4] : '',
                precio: element[5] ? element[5] : '',
                veropexcampo: element[6] ? element[6] : ''
              });
          });
          if (detallesAux.length > 0) {
            this.listado = detallesAux;
            this.height = 300;
          }
        };
        reader.readAsArrayBuffer(f);
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async guardar() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de importar la información?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        if (this.listado.length == 0) {
          this.alertDialog('error', 'No tiene información para importar');
        } else {
          this.$emit('importar', this.listado);
        }
      }
    },
    async exportarModelo() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de exportar el modelo de importación?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const fecha1 = moment().format('YYYYMMDDHHMMSS');
        const nombreentregable = 'EXPORT-MODELO-ARTICULOS-SERVICIOS-' + fecha1 + '.xlsx';
        const token = localStorage.getItem('token');
        await this.matrizService.downloadResource(
          'exportarModeloArticulosServicios',
          {
            token: token
          },
          nombreentregable
        );
        this.showLoading = false;
      }
    }
  },
  async created() {
    this.matrizService = this.$httpService(new MatrizService(), this);
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
